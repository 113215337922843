import { graphql } from 'gatsby';
import { BrowseByPA } from '../../components/browse-by-pa';

function PracticeAreas() {
  return (
    <main>
      <BrowseByPA title="Practice Areas" />
    </main>
  );
}

export const query = graphql`
  {
    wpPage(uri: {eq: "/practice-areas/"}) {
        ...SeoPage
      }
  }
`;

export default PracticeAreas;
export { Head } from '../../components/seo/seo';
